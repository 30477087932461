<template>
  <div>
    <v-text-field
      v-if="(current ? current.id : false) && !currentFocus"
      :value="current[itemText]"
      :label="label"
      outlined
      dense
      clearable
      prepend-inner-icon="mdi-database-search"
      @focus="focusOnAutoComplete"
    />
    <v-autocomplete
      v-else
      ref="autocomplete"
      v-model="localValue"
      :rules="rules"
      :required="required"
      :items="items"
      :search-input.sync="search"
      :loading="loading"
      :label="label"
      :item-text="itemText"
      :error-messages="errorMessages"
      :multiple="multiple"
      :chips="multiple"
      eager
      no-filter
      open-on-clear
      outlined
      clearable
      dense
      hide-no-data
      hide-selected
      prepend-inner-icon="mdi-database-search"
      return-object
      @blur="resetCurrent"
    >
      <template v-slot:item="data">
        <slot v-bind="data">
          {{ data.item[itemText] }}
        </slot>
      </template>
    </v-autocomplete>
  </div>
</template>
<script type="text/javascript">
import debounce from 'lodash.debounce'

export default {
  name: 'ApiAutoCompleteField',

  props: {
    itemText: {
      type: String,
      required: false,
      default: () => { return 'name' }
    },
    path: {
      type: String,
      required: false,
      default: () => { return null }
    },
    url: {
      type: String,
      required: false,
      default: () => { return null }
    },
    label: {
      required: false,
      default: () => { return null }
    },
    value: {
      required: true
    },
    requestParams: {
      type: Object,
      required: false,
      default: () => { return {} }
    },
    current: {
      type: Object,
      required: false,
      default: () => { return {} }
    },
    rules: {
      type: Array,
      required: false,
      default: () => { return [] }
    },
    required: {
      type: Boolean,
      required: false,
      default: () => { return false }
    },
    multiple: {
      type: Boolean,
      required: false,
      default: () => { return false }
    }
  },

  data () {
    return {
      currentFocus: false,
      error: {
        errors: {}
      },
      loading: false,
      items: [],
      search: null
    }
  },

  watch: {
    search () {
      this.getData()
    },
  },

  computed: {
    errorMessages () {
      var messages = []
      var errors = Object.keys(this.error.errors)

      if (errors.length > 0) {
        errors.forEach((errorKey) => {
          var error = this.error.errors[errorKey]
          messages.push(error[0])
        })
      } else if (this.error.message) {
        messages = [this.error.message]
      }

      return messages
    },
    localValue: {
      get () {
        return this.value
      },
      set (localValue) {
        this.$emit('input', localValue)
      }
    }
  },

  methods: {
    resetCurrent () {
      this.currentFocus = false
      this.$nextTick(() => {
        // this.$refs.current.focus()
      })
    },

    focusOnAutoComplete () {
      this.currentFocus = true
      this.search = this.current[this.itemText]
      this.$nextTick(() => {
        this.$refs.autocomplete.focus()
      })
    },

    getData: debounce(function () {
      this.loading = true
      this.$api.get({
        path: this.path,
        url: this.url,
        params: {
          ...{
            search: this.search,
          },
          ...this.requestParams
        }
      })
        .then(({ data }) => {
          this.items = data
        })
        .catch((error) => {
          this.error = error
        })
        .finally(() => this.loading = false)
    }, 400)
  }
}
</script>
