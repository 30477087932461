<template>
  <div>
    <v-chip
      class="ma-2"
      close
      @click:close="$emit('removeFilter', filter)"
    >
      {{ name }}
    </v-chip>
  </div>
</template>
<script type="text/javascript">
export default {
  name: 'TableFilterChip',

  props: {
    filter: {
      type: Object,
      required: true
    }
  },

  computed: {
    name () {
      var value = typeof this.filter.value === 'string' ? this.filter.value : this.filter.value.label
      return `${this.filter.key.label} ${this.filter.operator.label} ${value ? value : ''}`
    }
  }
}
</script>
