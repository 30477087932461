<template>
  <div>
    <v-row>
      <v-col
        :cols="12"
        :sm="6"
      >
        <TableFilters
          v-if="filterKeys.length > 0"
          :filter-keys="filterKeys"
          @update:filters="hanldeFiltersUpdate"
        />
      </v-col>
      <v-col
        :cols="12"
        :sm="6"
      >
        <div class="d-flex">
          <v-text-field
            v-model="search"
            class="mb-4"
            append-icon="mdi-magnify"
            label="Search"
            dense
            single-line
            hide-details
            outlined
          />
          <v-btn
            class="ml-2 refresh_btn"
            outlined
            dark
            depressed
            color="primary"
            @click="getData"
          >
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <v-data-table
      v-model="selected"
      :loading="loading"
      :headers="cols"
      :items="apiData.data"
      :search="search"
      :server-items-length="apiData.meta ? apiData.meta.total : 0"
      :options.sync="options"
      :show-select="showSelect"
      :mobile-breakpoint="0"
      :footer-props="{'items-per-page-options':[2 , 15, 30, 50, 100, 250, -1]}"
      :item-key="itemKey"
    >
      <template
        v-for="col in cols"
        :slot="`item.${col.value}`"
        slot-scope="item">
        <slot
          v-bind="item"
          :name="`item.${col.value}`"
        >
          {{ item.item[col.value] }}
        </slot>
      </template>
    </v-data-table>

    <SnackBar
      ref="errorBar"
      error
    >
      {{ error.message }}
    </SnackBar>
  </div>
</template>
<script type="text/javascript">
import debounce from 'lodash.debounce'
import SnackBar from '@/components/SnackBar'
import TableFilters from '@/components/DataTable/TableFilters'

export default {
  name: 'DataTable',

  components: {
    SnackBar,
    TableFilters
  },

  props: {
    itemKey: {
      type: String,
      required: false,
      default: () => { return 'id' }
    },
    path: {
      type: String,
      required: true
    },
    cols: {
      type: Array,
      required: false,
      default: () => { return [] }
    },
    requestParams: {
      type: Object,
      required: false,
      default: () => { return {} }
    },
    defaultOrderBy: {
      type: String,
      required: false,
      default: () => { return 'id' }
    },
    filterKeys: {
      type: Array,
      required: false,
      default: () => { return [] }
    },
    defaultAscending: {
      type: Boolean,
      required: false,
      default: () => { return false }
    },

    showSelect: {
      type: Boolean,
      required: false,
      default: () => { return true }
    }
  },

  data () {
    return {
      error: {},
      search: null,
      loading: false,
      filters: [],
      options: {
        page: 1,
        itemsPerPage: 15,
        sortBy: [],
        sortDesc: []
      },
      selected: [],
      apiData: {
        data: []
      }
    }
  },

  watch: {
    options: {
      handler () {
        this.loadData()
      },
      deep: true
    },
    filters: {
      handler () {
        this.getData()
      }
    },
    search: {
      handler () {
        this.getData()
      }
    }
  },

  methods: {
    hanldeFiltersUpdate (filters) {
      this.filters = filters
    },

    slotName (col) {
      return `item.${col.value}`
    },

    getData: debounce(function () {
      this.loadData()
    }, 200),

    loadData () {
      this.loading = true
      var ascending = (this.options.sortDesc.length > 0) ? (!this.options.sortDesc[0]) : this.defaultAscending
      this.$api.get({
        path: this.path,
        params: {
          ...{
            filters: this.filters,
            page: this.options.page,
            limit: this.options.itemsPerPage === -1 ? 1000 * 1000 : this.options.itemsPerPage,
            orderBy: this.options.sortBy[0] || this.defaultOrderBy,
            search: this.search,
            ascending: ascending ? 1 : 0
          },
          ...this.requestParams
        }
      })
        .then(data => this.apiData = data)
        .catch(error => {
          this.error = error
          this.$refs.errorBar.open()
        })
        .finally(() => this.loading = false)
    }
  }
}
</script>
<style type="text/css">
.refresh_btn {
  padding-top: 19px!important;
  padding-bottom: 19px!important;
}
.table_id {
    width: 90px;
    overflow: hidden;
    display: block;
    white-space: nowrap;
    position: relative;
    transition: width 0.5s;
}
.table_id:hover {
    width: 280px;
}
.table_id:hover::after {
    background-image: linear-gradient(90deg, hsla(0,0%,100%,0),#fff0 75%)!important;
}
.theme--light .table_id::after {
    content: "";
    height: 100%;
    width: 50%;
    background-color: transparent;
    z-index: 100;
    bottom: 0;
    right: 0;
    width: 100%;
    position: absolute;
}
.theme--light .table_id::after {
    background-image: linear-gradient(90deg, hsla(0,0%,100%,0),#fff 75%);
}
.theme--light.v-data-table tbody tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) .table_id::after {
   background-image: linear-gradient(90deg, hsla(0,0%,100%,0),#eee 75%);
}

.theme--dark .table_id::after {
    background-image: linear-gradient(90deg, hsla(0,0%,100%,0),#fff 75%);
}
.theme--dark.v-data-table tbody tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) .table_id::after {
   background-image: linear-gradient(90deg, hsla(0,0%,100%,0),#eee 75%);
}
</style>
