<template>
  <v-list
    :width="300"
    class="py-0"
    dense
  >
    <v-list-item
      class="bg-gray"
      @click="$emit('update:filter-operator', null)"
    >
      <v-list-item-title class="text-white">
        <v-icon
          color="white"
          small
          left
        >
          mdi-arrow-left-circle
        </v-icon>
        Back
      </v-list-item-title>
    </v-list-item>

    <v-list-item class="py-3">
      <v-text-field
        v-if="filterKey.type === 'String'"
        v-model="filter"
        :label="label"
        :autofocus="true"
        dense
        hide-details
        outlined
      />

      <v-select
        v-if="filterKey.type === 'Select'"
        v-model="filter"
        :items="filterKey.values"
        :label="label"
        :autofocus="true"
        item-text="label"
        return-object
        dense
        hide-details
        outlined
      />

      <v-select
        v-if="filterKey.type === 'Date' && filterOperator.key !== 'is_null'"
        v-model="filter"
        :items="dateOptions"
        :label="label"
        :autofocus="true"
        item-text="label"
        return-object
        dense
        hide-details
        outlined
      />

      <v-select
        v-if="filterKey.type === 'Date' && filterOperator.key === 'is_null'"
        v-model="filter"
        disabled
        :items="[{ key: null, label: 'Is Null' }]"
        :label="label"
        :autofocus="true"
        item-text="label"
        return-object
        dense
        hide-details
        outlined
      />

      <ApiAutoCompleteField
        v-if="filterKey.type === 'Lookup'"
        v-model="filter"
        :label="filterKey.lookup.label"
        :path="filterKey.lookup.path"
        :url="filterKey.lookup.url"
      />
    </v-list-item>

    <v-list-item v-if="filterKey.type === 'Date' && filter.key === 'CUSTOM'">
      <v-menu
        ref="customDateMenu"
        v-model="customDateMenu"
        :close-on-content-click="false"
        :return-value.sync="customFilter"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            :value="$moment(customFilter || $moment()).format('ddd Do MMM')"
            outlined
            dense
            label="Date"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="customFilter"
          no-title
          scrollable
        >
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="customDateMenu = false"
          >
            Cancel
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="$refs.customDateMenu.save(customFilter)"
          >
            OK
          </v-btn>
        </v-date-picker>
      </v-menu>
    </v-list-item>

    <v-list-item
      class="bg-primary"
      @click="addFilter"
    >
      <v-list-item-title class="text-white text-center">
        Add Filter
        <v-icon
          color="white"
          small
          right
        >
          mdi-plus-circle-outline
        </v-icon>
      </v-list-item-title>
    </v-list-item>
  </v-list>
</template>
<script type="text/javascript">
import ApiAutoCompleteField from '@/components/ApiAutoCompleteField'

export default {
  name: 'TableFilterValues',

  props: {
    filterOperator: {
      required: true,
      default: () => { return null }
    },
    filterKey: {
      required: true,
      default: () => { return null }
    }
  },

  components: {
    ApiAutoCompleteField
  },

  data () {
    return {
      filter: {},
      customFilter: null,
      customDateMenu: false
    }
  },

  computed: {
    label () {
      return `${this.filterKey.label} ${this.filterOperator.label}`
    },
    dateOptions () {
      return [
        { key: this.$moment().format('YYYY-MM-DD'), label: 'Today' },
        { key: this.$moment().add(1, 'days').format('YYYY-MM-DD'), label: 'Tomorrow' },
        { key: this.$moment().subtract(1, 'days').format('YYYY-MM-DD'), label: 'Yesterday' },
        { key: 'CUSTOM', label: 'Custom' }
      ]
    }
  },

  methods: {
    addFilter () {
      if (this.customFilter) {
        this.$emit('addFilter', { key: this.$moment(this.customFilter).format('YYYY-MM-DD'), label: this.$moment(this.customFilter).format('ddd Do MMM') })
      }
      else if (this.filterKey.type === 'Lookup') {
        this.$emit('addFilter', { key: this.filter[this.filterKey.lookup.return_value], label: this.filter[this.filterKey.lookup.return_label] })
      }
      else {
        this.$emit('addFilter', this.filter)
      }
    }
  }
}
</script>
