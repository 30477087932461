<template>
  <div>
    <v-menu
      v-model="showMenu"
      :z-index="101"
      :offset-y="true"
      :close-on-content-click="false"
    >
      <template v-slot:activator="{ on }">
        <v-btn
          color="primary"
          text
          dense
          v-on="on"
        >
          Add Filter
          <v-icon
            small
            right
          >
            mdi-filter-plus-outline
          </v-icon> 
        </v-btn>
      </template>

      <TableFilterKeys
        v-if="!newFilterKey"
        :filter-keys="filterKeys"
        :show-menu.sync="showMenu"
        :filter-key.sync="newFilterKey"
      />

      <TableFilterOperators
        v-if="newFilterKey && !newFilterOperator"
        :filter-operator.sync="newFilterOperator"
        :filter-key.sync="newFilterKey"
      />

      <TableFilterValues
        v-if="newFilterOperator"
        :filter-operator.sync="newFilterOperator"
        :filter-key.sync="newFilterKey"
        @addFilter="addFilter"
      />
    </v-menu>

    <TableFilterChip
      v-for="(filter, key) in filters"
      :key="key"
      v-bind="{ filter }"
      @removeFilter="handleRemoveFilter"
    />
  </div>
</template>
<script type="text/javascript">
import TableFilterOperators from '@/components/DataTable/TableFilterOperators'
import TableFilterKeys from '@/components/DataTable/TableFilterKeys'
import TableFilterValues from '@/components/DataTable/TableFilterValues'
import TableFilterChip from '@/components/DataTable/TableFilterChip'

export default {
  name: 'TableFilters',

  components: {
    TableFilterOperators,
    TableFilterKeys,
    TableFilterValues,
    TableFilterChip
  },

  props: {
    filterKeys: {
      type: Array,
      required: true
    }
  },

  data () {
    return {
      showMenu: false,
      filters: [],
      newFilterKey: null,
      newFilterOperator: null
    }
  },

  mounted () {
    if (this.$route.query.filters) {
      this.filters = [
        ...this.filters, ...JSON.parse(this.$route.query.filters)
      ]
    }
  },

  watch: {
    filters () {
      var query = this.$route.query
      var filters = JSON.stringify(this.filters)
      var newQuery = {
        ...query,
        ...{ filters }
      }

      if ((filters !== this.$route.query.filters)) {
        this.$router.push({ path: this.$route.name, query: newQuery })
      }

      this.$emit('update:filters', { ...JSON.parse(newQuery.filters), ...this.filters })
    }
  },

  methods: {
    handleRemoveFilter (filter) {
      this.filters.splice(this.filters.indexOf(filter), 1)
    },

    addFilter (value) {
      this.filters.push({
        operator: {
          key: this.newFilterOperator.key,
          label: this.newFilterOperator.label
        },
        key: {
          key: this.newFilterKey.key,
          type: this.newFilterKey.type,
          label: this.newFilterKey.label
        },
        value: value
      })

      this.newFilterKey = null
      this.newFilterOperator = null
      this.showMenu = false
    }
  }
}
</script>
